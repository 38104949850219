<template>
  <div class="promotion-form-wrapper">
    <el-form class="promotion-form" ref="promotion-form" :model="form" label-width="200px" label-position="left">
      <div class="create-promotion-form">
        <div>
          <el-form-item :label="$t('Promotion Name')" style="width: 100%">
            <el-input v-model="form.promotionName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Promotion Type')" style="width: 100%">
            <el-select v-model="form.promotionType">
              <el-option :label="$t('Percentage')" value="Percentage">
              </el-option>
              <el-option :label="$t('Deduction')" value="Deduction">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Valid Date Range')" style="width: 100%">
            <el-date-picker
            v-model="form.promotionDate"
            type="daterange"
            :range-separator="'|'"
            :start-placeholder="$t('Start Date')"
            :end-placeholder="$t('End Date')">
          </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Promotion Code')" style="width: 100%">
            <el-input v-model="form.promotionCode"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Discount Value')" style="width: 100%">
            <el-input v-model="form.promotionValue"></el-input>
          </el-form-item>
          <div class="save-button-wrapper">
            <button class="btn btn-primary saveButton" @click="create" v-if="actionType === 'create'">{{ $t('Create Promotion')}}</button>
            <button class="btn btn-primary saveButton" @click="update"v-if="actionType === 'update'">{{ $t('Update Promotion')}}</button>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';

export default {
  name: 'PromotionForm',
  props:{
    'actionType': String,
  },
  data(){
    const form = {
      'promotionName': '',
      'promotionType': '',
      'promotionCode': '',
      'promotionDate': [],
      'promotionValue': '',
    };
    return {
      form,
    };
  },
  methods:{
    create(){
      this.$emit('action', this.form);
    },
    update(){
      const promotionId = this.currentPromotion.id;
      this.$emit('action', this.form, promotionId);
    },
  },
  watch: {
    'currentPromotion': function(){
      const form = {
        'promotionName': this.currentPromotion.promotion_name,
        'promotionType': this.currentPromotion.promotion_type,
        'promotionCode': this.currentPromotion.promotion_code,
        'promotionDate': [],
        'promotionValue': this.currentPromotion.promotion_value,
      };
      form.promotionDate[0] = dayjs.unix(this.currentPromotion.promotion_valid_date_start);
      form.promotionDate[1] = dayjs.unix(this.currentPromotion.promotion_valid_date_end);
      this.form = form;
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
    langcode: state => state.langcode,
    currentPromotion: state => state.promotion.currentPromotion,
  }),
}
</script>

<style lang="scss">
.promotion-form-wrapper{
  padding-left: 30px;
}
.save-button-wrapper{
  text-align: right;
}
</style>
